import saladStoryIcon from '@rewardopl/assets/logos/saladstory.svg';

import { Wrapper } from './header.styles';

import { VENDOR_BUSINESS_NAME } from '../constants';

export default function Header() {
  return (
    <Wrapper>
      <img width={233} height={79} src={saladStoryIcon} alt={VENDOR_BUSINESS_NAME} />
    </Wrapper>
  );
}
