import { PROD } from './env';

import type {
  ChallengeReward,
  CouponAvailability,
  CouponBenefits,
  CouponUsage,
  NotificationCategory,
  PromotionGroupBenefits,
  StampValue,
} from '@rewardopl/types';

export const APP_ID = 'com.rewardoapp.saladstory';

export const CHALLENGE_REWARD_TYPES = {
  POINTS: 'points',
  REWARD: 'reward',
  NONE: 'none',
} as const satisfies Record<string, ChallengeReward['type']>;

export const COUNTRIES = {
  GERMANY: 'GERMANY',
  POLAND: 'POLAND',
  UK: 'UK',
} as const;

export const COUNTRY_CALLING_CODES = {
  GERMANY: '+49',
  POLAND: '+48',
  UK: '+44',
} as const satisfies Record<keyof typeof COUNTRIES, string>;

export const COUNTRY_CODES = {
  GERMANY: 'DE',
  POLAND: 'PL',
  UK: 'UK',
} as const satisfies Record<keyof typeof COUNTRIES, string>;

export const COUNTRY_NAMES = {
  GERMANY: 'Germany',
  POLAND: 'Poland',
  UK: 'United Kingdom',
} as const satisfies Record<keyof typeof COUNTRIES, string>;

export const COUPON_AVAILABILITY_TYPES = {
  DATETIME_RANGE: 'datetime_range',
  HOURS: 'hours',
  UNLIMITED: 'unlimited',
} as const satisfies Record<string, CouponAvailability['type']>;

export const COUPON_BENEFIT_TYPES = {
  DISCOUNT_PERCENTAGE: 'discount_percentage',
  DISCOUNT_AMOUNT: 'discount_amount',
  STAMP_MULTIPLY: 'stamp_multiply',
  FREEBIE: 'freebie',
} as const satisfies Record<string, CouponBenefits['type']>;

export const COUPON_USAGE_TYPES = {
  ONCE: 'once',
  ONCE_EVERY: 'once_every',
  UNLIMITED: 'unlimited',
} as const satisfies Record<string, CouponUsage['type']>;

const isProduction = PROD;

export const GOOGLE_MAPS_API_KEY = isProduction
  ? 'AIzaSyD4Zbjw4ZtW25fD1dva_4_MZYmKo51nKlw'
  : 'AIzaSyADtUz4xTPae1kc8BZem8e0jUBgAHmMFZw';

export const VENDOR_BUSINESS_ID = '525fe9dd-34de-4d56-a700-fc0dcec23ab6';

export const VENDOR_BUSINESS_NAME = 'Salad Story';

export const VENDOR_CARD_ID = '46b8ca84-3483-473b-ba82-a69b3aa5fc07';

export const MOBILE_PHONE_PREFIXES = {
  GERMANY: ['15', '16', '17'],
  POLAND: ['45', '50', '51', '53', '57', '60', '66', '69', '72', '73', '78', '79', '88'],
  UK: ['71', '73', '74', '75', '76', '77', '78', '79'],
} as const satisfies Record<keyof typeof COUNTRIES, unknown>;

export const NOTIFICATION_CATEGORIES = {
  CHALLENGES: 'challenges',
  COUPONS: 'coupons',
  GENERAL: 'general',
  MESSAGES: 'messages',
  PRODUCTS: 'products',
} as const satisfies Record<string, NotificationCategory>;

export const PHONE_FORMATS = {
  GERMANY: {
    LANDLINE: '## ######',
    MOBILE: '#### #######',
  },
  POLAND: {
    LANDLINE: '## ### ## ##',
    MOBILE: '### ### ###',
  },
  UK: {
    LANDLINE: '## #### ####',
    MOBILE: '#### ### ###',
  },
} as const satisfies Record<
  keyof typeof COUNTRIES,
  {
    LANDLINE: string;
    MOBILE: string;
  }
>;

export const PROMOTION_GROUP_BENEFIT_TYPES = {
  DISCOUNT_PERCENTAGE: 'discount_percentage',
  DISCOUNT_AMOUNT: 'discount_amount',
  STAMP_MULTIPLY: 'stamp_multiply',
  CUSTOM: 'custom',
  NONE: 'none',
} as const satisfies Record<string, PromotionGroupBenefits['type']>;

export const STAMP_VALUES = {
  ABOVE: 'above',
  EACH: 'each',
  ITEM: 'item',
  VISIT: 'visit',
  CUSTOM: 'custom',
  UNDEFINED: 'undefined',
} as const satisfies Record<string, StampValue['type']>;

export const VAPID_PUBLIC_KEY =
  'BIpaZwB3vc2m2UPW1L1_9--qRC2F1A6eLIIKv6Cq4eZq587pwhLJDmAcI_2Nr6FZfTWydqJiXw5arUgHvGO6074';

export const WEEKDAYS = {
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
  SUNDAY: 'SUNDAY',
} as const;

export const WEEKDAY_NAMES = {
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday',
} as const satisfies Record<keyof typeof WEEKDAYS, string>;

export const WEEKDAY_ABBRS = {
  MONDAY: 'Mo',
  TUESDAY: 'Tu',
  WEDNESDAY: 'We',
  THURSDAY: 'Th',
  FRIDAY: 'Fr',
  SATURDAY: 'Sa',
  SUNDAY: 'Su',
} as const satisfies Record<keyof typeof WEEKDAYS, string>;
