import { useAtomValue } from 'jotai';
import { useTranslation } from '@wojtekmaj/react-t';

import {
  HeaderWrapper,
  LogoWrapper,
  HeaderContentWrapper,
  MessageDate,
  Subject,
  Sender,
  Body,
  Picture,
} from './message_content.styles';

import Logo from '../../../../common/logo';
import MessagePoll from './message_poll';

import { formatRelative } from '../../../../../utils/date';
import { addLinks } from '../../../../../utils/helpers';

import { businessQuery, vendorMessageQuery } from '../../../../../store';

import type { Message } from '@rewardopl/types';

type MessageContentProps = {
  buttonClassName?: string;
  messageId: Message['_id'];
};

export default function MessageContent({ buttonClassName, messageId }: MessageContentProps) {
  const message = useAtomValue(vendorMessageQuery(messageId));

  const { business_id: businessId } = message;

  const business = useAtomValue(businessQuery(businessId));

  const pictureString = useTranslation('Picture');

  return (
    <>
      <HeaderWrapper>
        <LogoWrapper>
          <Logo borderWidth={0} business={business} size={64} />
        </LogoWrapper>
        <HeaderContentWrapper>
          <MessageDate>{formatRelative(message.created_at)}</MessageDate>
          <Subject>{message.subject}</Subject>
          <Sender>{business.name}</Sender>
        </HeaderContentWrapper>
      </HeaderWrapper>
      {message.attachment ? (
        <Picture alt={pictureString} src={`/api/uploads/${message.attachment}`} />
      ) : null}
      <Body>{addLinks(message.body)}</Body>
      <MessagePoll buttonClassName={buttonClassName} messageId={messageId} />
    </>
  );
}
