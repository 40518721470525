import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSetAtom } from 'jotai';
import T, { useTranslation } from '@wojtekmaj/react-t';
import AsyncButton from '@wojtekmaj/react-async-button';

import { toast } from '@rewardopl/react-toast';

import Button from '@rewardopl/react-ui/button';
import CheckboxField from '@rewardopl/react-ui/checkbox_field';
import InputField from '@rewardopl/react-ui/input_field';

import { post } from '@rewardopl/utils/network';

import { Form, ButtonWrapper, Indent, Line, Small } from '../login/email/form.styles';

import TermsAndConditionsLink from '../../terms_and_conditions_link';
import PrivacyPolicyLink from '../../privacy_policy_link';

import { isPasswordValid } from '../../../utils/password';

import useLocationParam from '../../../hooks/useLocationParam';

import { cardSubscriptionsState, maybeCurrentUserState } from '../../../store';

import { APP_ID, VENDOR_CARD_ID } from '../../../constants';

import type { CardSubscription, User } from '@rewardopl/types';

const pendingConfig = {
  disabled: true,
};

export default function RegisterForm() {
  const location = useLocation();
  const [referralId] = useLocationParam('referral_id');
  const navigate = useNavigate();
  const setCurrentUser = useSetAtom(maybeCurrentUserState);
  const setCardSubscriptions = useSetAtom(cardSubscriptionsState);
  const registerErrorString = useTranslation('Failed to register');
  const userAlreadyExistsString = useTranslation('User already exists');
  const passwordInvalidString = useTranslation(
    'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.',
  );

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailMarketingAccepted, setEmailMarketingAccepted] = useState(false);
  const [smsMarketingAccepted, setSmsMarketingAccepted] = useState(false);
  const marketingAccepted = emailMarketingAccepted || smsMarketingAccepted;

  const { returnPath = '/' } = (location.state || {}) as { returnPath?: string };

  const action = '/api/users';

  async function onClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();

    const submitButton = event.currentTarget;
    const form = submitButton.form;

    if (!form || !form.reportValidity()) {
      return;
    }

    const formData = new FormData(form);

    try {
      const user = (await post(action, {
        headers: {
          'app-id': APP_ID,
        },
        body: formData,
      })) as User;

      const response = (await post(`/api/users/${user._id}/card_subscriptions`, {
        body: { card_id: VENDOR_CARD_ID },
      })) as CardSubscription;

      setCurrentUser(user);
      setCardSubscriptions([response]);
    } catch (error) {
      if (error instanceof Error && error.message === 'User already exists') {
        toast.error(userAlreadyExistsString);
        navigate('/login/email', { state: { email: formData.get('email'), returnPath } });
        return;
      }

      toast.error(registerErrorString);
    }
  }

  return (
    <Form action={action} method="POST">
      <input name="referral_id" value={referralId} type="hidden" />
      <InputField
        autoComplete="username"
        label="Email"
        maxLength={320}
        name="email"
        onChange={setEmail}
        placeholder="email@example.com"
        type="email"
        value={email}
      />
      <InputField
        autoComplete="new-password"
        hint="Min. 8 characters, uppercase letter, lowercase letter, number, special character"
        label="Password"
        minLength={8}
        name="password"
        onChange={(nextPassword, event) => {
          setPassword(nextPassword);

          event.target.setCustomValidity(
            isPasswordValid(nextPassword, email) ? '' : passwordInvalidString,
          );
        }}
        placeholder="••••••••"
        type="password"
        value={password}
      />
      <CheckboxField
        name="terms_accepted"
        label={
          <>
            <T>I have read and agree to the</T> <TermsAndConditionsLink /> <T>and</T>{' '}
            <PrivacyPolicyLink />.
          </>
        }
        required
      />
      <CheckboxField
        checked={marketingAccepted}
        name="saladstory_marketing_accepted"
        label={
          <T>
            I consent to Salad Story S.A. providing information about the latest promotions,
            products and services of its own and about the promotions, products and services of its
            partners via e-mail or push messages.
          </T>
        }
        onChange={() => {
          setEmailMarketingAccepted(!marketingAccepted);
          setSmsMarketingAccepted(!marketingAccepted);
        }}
      />
      <Indent>
        <CheckboxField
          checked={smsMarketingAccepted}
          name="saladstory_marketing_sms_accepted"
          label={<T>SMS</T>}
          onChange={() => {
            setSmsMarketingAccepted(!smsMarketingAccepted);
          }}
        />
        <CheckboxField
          checked={emailMarketingAccepted}
          name="saladstory_marketing_email_accepted"
          label={<T>E-mail</T>}
          onChange={() => {
            setEmailMarketingAccepted(!emailMarketingAccepted);
          }}
        />
      </Indent>
      <Line />
      <Small>
        <T link={<a href="mailto:kontakt@saladstory.com">kontakt@saladstory.com</a>}>
          {
            'You can withdraw your consent at any time. Withdrawal of consent does not affect the legality of the marketing information sent so far. To do this, write to us at {link} or log in and change the settings in your account.'
          }
        </T>
      </Small>
      <Small>
        <T link={<PrivacyPolicyLink />}>
          {
            'The administrator of your personal data is Salad Story S.A. with its registered office in Warsaw (02-222), Al. Jerozolimskie 179. Your personal data will be processed for the purpose of registering and managing your user account in the My Salad Story loyalty program, including sending you marketing messages related to your participation in the loyalty program (also using profiling), as well as for the statistical and analytical purposes of the administrator. In the event of expressing appropriate consent, your data will also be processed for the purpose of sending you marketing content in the form of e-mail, to the e-mail address provided. More information on the processing of personal data, including your rights, can be found in our {link}.'
          }
        </T>
      </Small>
      <ButtonWrapper>
        <AsyncButton
          as={Button}
          className="primary"
          onClick={onClick}
          pendingConfig={pendingConfig}
          type="submit"
        >
          <T>Register</T>
        </AsyncButton>
      </ButtonWrapper>
    </Form>
  );
}
