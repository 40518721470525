import { useEffect, useRef, useState } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';

import { get } from '@rewardopl/utils/network';
import { stringify } from '@rewardopl/utils/url';

import TransactionsMonitorDialog from './dialog';

import useVendorBusinessFlag from '../../../../hooks/useVendorBusinessFlag';

import {
  vendorCardState,
  vendorCardSubscriptionState,
  pointsTransactionsQuery,
  transactionsQuery,
} from '../../../../store';

import type { Transaction } from '@rewardopl/types';
import type { BaseDocument } from '@rewardopl/types/helpers/base';

function findFirstNewArrayElement<T extends BaseDocument>(
  newArray: T[],
  oldArray: T[],
): T | undefined {
  return newArray.find(
    (transaction) => !oldArray.find((oldTransaction) => oldTransaction._id === transaction._id),
  );
}

type TransactionsMonitorProps = {
  closeDialogAndWaitForDestroyed: () => Promise<void>;
};

let isRefreshing = false;

function Watcher({ closeDialogAndWaitForDestroyed }: TransactionsMonitorProps) {
  const cardSubscription = useAtomValue(vendorCardSubscriptionState);
  const { _id: cardSubscriptionId } = cardSubscription;

  const prevTransactions = useRef<Transaction[] | null>(null);
  const transactions = useAtomValue(
    pointsTransactionsQuery({ id: cardSubscriptionId, offset: 0, limit: 5 }),
  );
  const setTransactions = useSetAtom(
    transactionsQuery({ id: cardSubscriptionId, offset: 0, limit: 5 }),
  );
  const [transactionForFeedback, setTransactionForFeedback] = useState<Transaction | null>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies: useEffect must run when cardSubscription changes
  useEffect(() => {
    if (isRefreshing) {
      return;
    }

    isRefreshing = true;

    (
      get(
        `/api/card_subscriptions/${cardSubscriptionId}/transactions${stringify({ offset: 0, limit: 5 })}`,
      ) as Promise<Transaction[]>
    )
      .then(setTransactions)
      .catch(() => {
        // Do nothing
      })
      .finally(() => {
        isRefreshing = false;
      });
  }, [cardSubscription, setTransactions]);

  useEffect(() => {
    if (!prevTransactions.current || transactionForFeedback) {
      prevTransactions.current = transactions;
      return;
    }

    const nextTransactionForFeedback = findFirstNewArrayElement(
      transactions,
      prevTransactions.current,
    );

    if (nextTransactionForFeedback) {
      closeDialogAndWaitForDestroyed().then(() => {
        setTransactionForFeedback(nextTransactionForFeedback);
      });
    }

    prevTransactions.current = transactions;
  }, [closeDialogAndWaitForDestroyed, transactionForFeedback, transactions]);

  if (!transactionForFeedback) {
    return null;
  }

  function closeDialog() {
    setTransactionForFeedback(null);
  }

  return <TransactionsMonitorDialog onDismiss={closeDialog} transaction={transactionForFeedback} />;
}

export default function TransactionsMonitor({
  closeDialogAndWaitForDestroyed,
}: TransactionsMonitorProps) {
  const shouldDisplayFeedback = useVendorBusinessFlag('feedback');
  const card = useAtomValue(vendorCardState);

  const { is_feedback_enabled: isFeedbackEnabled } = card;

  if (!shouldDisplayFeedback || !isFeedbackEnabled) {
    return null;
  }

  return <Watcher closeDialogAndWaitForDestroyed={closeDialogAndWaitForDestroyed} />;
}
